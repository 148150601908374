<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'

export default {
  name: 'dictionary',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/dict/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '字典名称',
          type: 'input',
          key: 'name'
        },
        {
          label: '',
          type: 'text',
          key: ''
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-90',
          isId: true,
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '字典名称',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'code',
          title: '字典编码',
          type: 'lt-100',
          sorter: (a, b) => a.code.length - b.code.length
        },
        {
          dataIndex: 'type',
          title: '字典类型',
          width: 60,
          align: 'center',
          sorter: (a, b) => a.type.length - b.type.length
        },
        {
          dataIndex: 'remark',
          title: '备注',
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.remark.length - b.remark.length
        },
        {
          dataIndex: 'createBy',
          isId: true,
          title: '创建人',
          type: 'lt-100',
          sorter: (a, b) => a.createBy - b.createBy
        },
        {
          dataIndex: 'createTime',
          isId: true,
          title: '创建时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix()
        },
        {
          dataIndex: 'updateBy',
          isId: true,
          title: '修改人',
          type: 'lt-100',
          sorter: (a, b) => a.updateBy - b.updateBy
        },
        {
          dataIndex: 'updateTime',
          isId: true,
          title: '修改时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.updateTime).unix() - moment(b.updateTime).unix()
        },
        {
          dataIndex: 'sys',
          title: '系统内置',
          width: 80,
          type: 'badge',
          onExport: records => {
            return ['否', '是'][records]
          },
          filters: [
            {
              text: '是',
              value: '1'
            },
            {
              text: '否',
              value: '0'
            }
          ],
          onFilter: (value, record) => record.internal == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '否' : '是',
              color: data == 0 ? 'red' : 'green'
            }
          }
        },

        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              //   {
              //     name: records.status == 0 ? '失效' : '正常',
              //     onClick: () => {
              //       api.command.edit.call(this, {
              //         url: `/app/${
              //           records.status == 0 ? 'disable' : 'enable'
              //         }?appId=${records.appId}`
              //       })
              //     }
              //   },
              {
                name: '字典数据',
                onClick: () => this.$router.push(`/system/dictionaryChildren?code=${records.code}`)
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/system/dictionaryDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/dict/${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/system/dictionaryDetail')
        }
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //
        //       }
        //     }
        //   ]
        // }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
